import Vue from 'vue'
import router from './router'
import store from './store'
import { routerArr } from '@/utils/routerUtil'
// 白名单
const whiteList = ['/Login', '/SchoolList']
router.beforeEach((to, from, next) => {
    if (whiteList.includes(to.path)) {
        next()
    } else {
        // 判断是否已经登录
        if (!store.getters['user/accessToken']) {
            // 重定向到登录界面
            next({ path: '/Login'})
        } else {
            let menuTree = store.getters['user/authMenuTree']
            if (menuTree.length === 0) {
                store.dispatch('user/MenuTree').then(menu => {
                    router.addRoutes(menu)
                }).catch(error => {
                    store.dispatch('user/Logout').then(() => {
                        // 重定向到登录界面
                        next({ path: '/Login'})
                    })
                })
            }
            next()
        }
    }
})

// 按钮操作权限
const action = Vue.directive('action', {
    /**
     * vue 属性绑定
     * @param {*} el htmldom
     * @param {*} binding 绑定的值
     * @param {*} vnode 虚拟dom节点
     */
    bind: function (el, binding, vnode) {
        const actionName = binding.arg
        const actions = vnode.context.$route.meta.permissions.find((val) => {
            return actionName === val
        })
        if (!actions) {
            el.parentNode && el.parentNode.removeChild(el) || (el.style.display = 'none')
        }
    }
})

export {
    action
}
