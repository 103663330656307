export const routerArr =  (data) => {
    let routerMap = [
        {
            name: '首页',
            path: '/home',
            type: 2,
            icon: 'home',
            hidden: 0,
            component: () => import('@/views/home/Index'),
            meta: {
                breadcrumb: [{ icon: 'home', name: '首页', type: 2 }],
                permissions: []
            },
            children: []
        }
        // {
        //     name: '个人中心',
        //     path: '/userCenter/UserCenter',
        //     type: 2,
        //     icon: 'user',
        //     hidden: 0,
        //     component: () => import('@/views/userCenter/UserCenter'),
        //     meta: {
        //         breadcrumb: [{ icon: 'user', name: '个人中心', type: 2 }],
        //         permissions: []
        //     },
        //     children: []
        // }
    ]
    routerMap = buildTree(data, routerMap, [], null)
    let routers = [
        {
            path: '/',
            name: '',
            component: () => import('@/layout/BaseLayout'),
            children: routerMap
        }
    ]
    return routers
}

/**
 * 构建菜单树
 * @param list
 * @param arr
 */
export function buildTree (menus, routerMap, breadcrumb, parentMenu ) {
    let jsonBreadcrumb = JSON.parse(JSON.stringify(breadcrumb))
    if (parentMenu != null) {
        jsonBreadcrumb.push({ icon: parentMenu.icon, name: parentMenu.name, type: parentMenu.type })
    }
    let parentBreadcrumb = jsonBreadcrumb
    menus.map(menu => {
        let permissions = []
        if (menu.children) {
            menu.children.map(item => {
                let btnPermission = item.btnPermission
                if (btnPermission != null) {
                    permissions.push(btnPermission)
                }
            })
        }
        // 取出所有的页面级
        if (menu.type === 2) {
            let newBreadcrumb = JSON.parse(JSON.stringify(parentBreadcrumb))
            newBreadcrumb.push({ icon: menu.icon, name: menu.name, type: menu.type })
            let child = {
                name: menu.name,
                path: menu.url,
                component: () => import(`@/views${menu.url}`),
                meta: {
                    breadcrumb: newBreadcrumb,
                    permissions: permissions
                }
            }
            routerMap.push(child)
        }
        if (menu.children) {
            buildTree(menu.children, routerMap, parentBreadcrumb, menu)
        }
    })
    return routerMap
}
