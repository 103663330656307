import axios from '../axios'



/**
 * 1.1.3 添加设施
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const save = data => {
    return axios({
        url: '/manager/houseFacil/save',
        method: 'POST',
        data
    })
}

/**
 * 1.5.1  设施
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
 export const checked = (houseId) => {
    return axios({
        url: '/manager/houseFacil/checked/' + houseId,
        method: 'GET'
    })
}

