import Storage from '@/utils/storagehandle'
const app = {
    namespaced: true,
    state: {
        menuAction: Storage.getItem('menuAction') || '/'
    },
    mutations: {
        CHANGE_MENU_ACTION (state, menuAction) {
            state.menuAction = menuAction
            Storage.setItem(`menuAction`, state.menuAction)
        }
    },
    actions: {
    },
    getters: {
        menuAction: state => state.menuAction
    }
}
export default app
