import axios from '../axios'

/**
 * 1.1.1 获取所有菜单列表
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const findNavMenuTree = () => {
    return axios({
        url: '/manager/menu/findNavMenuTree',
        method: 'get'
    })
}

/**
 * 1.1.2 删除菜单
 * @param menuId
 * @returns {Promise | Promise<unknown>}
 */
export const del = menuId => {
    return axios({
        url: '/manager/menu/del/' + menuId,
        method: 'DELETE'
    })
}

/**
 * 1.1.3 添加菜单
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const add = data => {
    return axios({
        url: '/manager/menu/add',
        method: 'POST',
        data
    })
}

/**
 * 1.1.4 修改菜单
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const update = data => {
    return axios({
        url: '/manager/menu/update',
        method: 'PUT',
        data
    })
}

/**
 * 1.1.5 获取角色菜单树
 * @param roldId
 * @param schoolId
 * @returns {Promise | Promise<unknown>}
 */
export const findRoleMenuTree = (roleId) => {
    return axios({
        url: '/manager/menu/findRoleMenuTree/' + roleId,
        method: 'GET'
    })
}
