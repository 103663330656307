import axios from '../axios'

/**
 * 1.0.1 获取登录凭证
 * @param {*} data
 */
export const oauthToken = data => {
    return axios({
        url: '/manager/login/token',
        method: 'POST',
        data: data,
        headers: {
            isToken: false
        }
    })
}

/**
 * 1.0.2 退出登录
 * @returns {Promise | Promise<unknown>}
 */
export const loginOut = () => {
    return axios({
        url: '/manager/login/loginOut',
        method: 'POST'
    })
}
