import axios from '../axios'




/**
 * 查询设置
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
 export const list = () => {
    return axios({
        url: '/manager/sysFacil/list',
        method: 'GET'
    })
}
