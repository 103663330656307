import axios from '../axios'

/**
 * 1.5.1  分页获取用户信息
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const page = (pageNum, pageSize) => {
    return axios({
        url: '/manager/userAccount/page/' + pageNum + '/' + pageSize,
        method: 'GET'
    })
}

/**
 * 1.5.2  添加用户
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const add = data => {
    return axios({
        url: '/manager/userAccount/add',
        method: 'POST',
        data
    })
}

/**
 * 1.5.3  修改用户
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const update = data => {
    return axios({
        url: '/manager/userAccount/update',
        method: 'PUT',
        data
    })
}

/**
 * 1.5.4  重置用户密码
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const resetUserPwd = data => {
    return axios({
        url: '/manager/userAccount/resetUserPwd',
        method: 'PUT',
        data
    })
}

/**
 * 1.5.5  获取用户信息
 * @returns {Promise | Promise<unknown>}
 */
export const findUserData = () => {
    return axios({
        url: '/manager/userAccount/findUserData',
        method: 'GET'
    })
}

/**
 * 1.5.6  用户修改个人资料
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const updateBySelf = data => {
    return axios({
        url: '/manager/userAccount/updateBySelf',
        method: 'PUT',
        data
    })
}

/**
 * 1.5.7  用户自己重置密码
 * @returns {Promise | Promise<unknown>}
 */
export const resetPwdBySelf = data => {
    return axios({
        url: '/manager/userAccount/resetPwdBySelf',
        method: 'PUT',
        data
    })
}

/**
 * 1.5.1  删除
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
 export const del = (id) => {
    return axios({
        url: '/manager/userAccount/del/' + id,
        method: 'DELETE'
    })
}
