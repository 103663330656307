/**
 * 基础路由
 */
export const constantRouterMap = [
    {
        path: '/Login',
        name: 'Login',
        component: () => import('@/views/Login')
    }
    // {
    //     path: '/SchoolList',
    //     name: 'SchoolList',
    //     component: () => import('@/views/schools/SchoolList')
    // }
]
