import axios from '../axios'



/**
 * 1.1.3 添加/修改客户信息
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const save = data => {
    return axios({
        url: '/manager/customer/save',
        method: 'POST',
        data
    })
}

/**
 * 1.5.1  分页获取客户信息
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
 export const page = (pageNum, pageSize, keywords) => {
    return axios({
        url: '/manager/customer/page/' + pageNum + '/' + pageSize + '/' + keywords,
        method: 'GET'
    })
}


/**
 * 1.5.1  删除客户信息
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
 export const del = (id) => {
    return axios({
        url: '/manager/customer/del/' + id,
        method: 'DELETE'
    })
}