import Storage from '@/utils/storagehandle'
import apis from '@/http/api.js'
import { routerArr } from '@/utils/routerUtil'
const user = {
    namespaced: true,
    state: {
        // 登录的用户信息
        userInfo: Storage.getItem('userInfo') || null,
        schoolInfo: Storage.getItem('schoolInfo') || null,
        // 权限菜单
        // authMenuTree: Storage.getItem('menuTree') || [],
        authMenuTree: [],
        accountId: Storage.getItem('accountId') || null,
        nickName: Storage.getItem('nickName') || null,
        accessToken: Storage.getItem('accessToken') || '',
        refreshToken: Storage.getItem('refreshToken')||'',
        expiresIn: Storage.getItem('expiresIn')||0,
        timeStamp: Storage.getItem('timeStamp')||0
    },
    mutations: {
        ['USER_CHANGE_USER_INFO'] (state, userInfo) {
            state.userInfo = userInfo
            Storage.setItem(`userInfo`, state.userInfo)
        },
        ['USER_CHANGE_SCHOOL_INFO'] (state, schoolInfo) {
            state.schoolInfo = schoolInfo
            Storage.setItem(`schoolInfo`, state.schoolInfo)
        },
        ['USER_CHANGE_AUTH_MENU_TREE'] (state, authMenuTree) {
            state.authMenuTree = authMenuTree
            // Storage.setItem(`menuTree`, authMenuTree)
        },
        ['USER_CHANGE_ACCESS_TOKEN'] (state, accessToken) {
            state.accessToken = accessToken
            Storage.setItem(`accessToken`, state.accessToken)
        },
        ['USER_CHANGE_REFRESH_TOKEN'] (state, refreshToken) {
            state.refreshToken = refreshToken
            Storage.setItem(`refreshToken`, state.refreshToken)
        },
        ['USER_CHANGE_EXPIRES_IN'] (state, expiresIn) {
            state.expiresIn = expiresIn
            Storage.setItem(`expiresIn`, state.expiresIn)
        },
        ['USER_CHANGE_TIME_STAMP'] (state, timeStamp) {
            state.timeStamp = timeStamp
            Storage.setItem(`timeStamp`, state.timeStamp)
        }
    },
    actions: {
        Login ({ commit }, params) {
            return new Promise((resolve, reject) => {
                apis.login.oauthToken(params).then(response => {
                    if (response.status > 0) {
                        reject(response.msg)
                        return
                    }
                    commit('USER_CHANGE_USER_INFO', response.data.userInfo)
                    commit('USER_CHANGE_ACCESS_TOKEN', response.data.accessToken)
                    commit('USER_CHANGE_REFRESH_TOKEN', response.data.refreshToken)
                    commit('USER_CHANGE_EXPIRES_IN', response.data.expiresIn)
                    // 记录一个当前时间戳
                    commit('USER_CHANGE_TIME_STAMP', Date.parse(new Date()))
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        MenuTree ({ commit, state }) {
            return new Promise((resolve, reject) => {
                apis.menu.findNavMenuTree().then(response => {
                    if (response.status > 0) {
                        reject(response.msg)
                        return
                    }
                    let routAuth = routerArr(response.data)
                    console.log('routAuth', routAuth)
                    commit('USER_CHANGE_AUTH_MENU_TREE', response.data)
                    resolve(routAuth)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        Logout ({ commit }) {
            return new Promise((resolve) => {
                apis.login.loginOut().then(() => {
                    commit('USER_CHANGE_ACCESS_TOKEN', '')
                    commit('USER_CHANGE_AUTH_MENU_TREE', [])
                    commit('USER_CHANGE_USER_INFO', null)
                    commit('USER_CHANGE_REFRESH_TOKEN', '')
                    commit('USER_CHANGE_EXPIRES_IN', '')
                    // 记录一个当前时间戳
                    commit('USER_CHANGE_TIME_STAMP', 0)
                    resolve()
                }).catch(() => {
                    resolve()
                })
            })
        }
    },
    getters: {
        userInfo: state => state.userInfo,
        schoolInfo: state => state.schoolInfo,
        authMenuTree: state => state.authMenuTree,
        accountId: state => state.userInfo.userId,
        nickName: state => state.userInfo.nickname,
        accessToken: state => state.accessToken,
        refreshToken: state => state.refreshToken,
        expiresIn: state => state.expiresIn,
        timeStamp: state => state.timeStamp
    }
}
export default user
