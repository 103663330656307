import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import app from './modules/app'

// 挂载Vuex
Vue.use(Vuex)
const state = {}
const mutations = {}
const actions = {}
const getters = {}
//创建VueX对象
export default new Vuex.Store({
    modules: {
        user,
        app
    },
    state,
    mutations,
    actions,
    getters
})
