import axios from '../axios'



/**
 * 1.1.3 添加/修改盘源信息
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const save = data => {
    return axios({
        url: '/manager/diskSource/save',
        method: 'POST',
        data
    })
}

/**
 * 1.5.1  分页获取盘源信息
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
 export const page = (pageNum, pageSize, keywords, type, status) => {
    return axios({
        url: '/manager/diskSource/page/' + pageNum + '/' + pageSize + '/' + keywords + '/' + type + '/' + status,
        method: 'GET'
    })
}


/**
 * 1.5.1  删除盘源信息
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
 export const del = (id) => {
    return axios({
        url: '/manager/diskSource/del/' + id,
        method: 'DELETE'
    })
}

/**
 * 1.5.1  修改盘源状态
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
 export const updateStatus = (id, status) => {
    return axios({
        url: '/manager/diskSource/updateStatus/' + id + '/' + status,
        method: 'GET'
    })
}

/**
 * 1.5.1  盘源查看次数
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
 export const look = (diskId) => {
    return axios({
        url: '/manager/diskLook/look/' + diskId,
        method: 'GET'
    })
}