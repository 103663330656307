import axios from '../axios'

/**
 * 上传图片
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const fileUpload = data => {
    return axios({
        url: '/sys/file/fileUpload',
        method: 'post',
        data,
        headers: {
            isToken: false
        }
    })
}
