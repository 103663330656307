import axios from '../axios'



/**
 * 1.1.3 添加/修改小区信息
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const save = data => {
    return axios({
        url: '/manager/village/save',
        method: 'POST',
        data
    })
}

/**
 * 1.5.1  分页获取小区信息
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
 export const page = (pageNum, pageSize, keywords) => {
    return axios({
        url: '/manager/village/page/' + pageNum + '/' + pageSize + '/' + keywords,
        method: 'GET'
    })
}
