import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './http/index'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import './permission'
import VueAMap from 'vue-amap'
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: '95fa72137f4263f8e64ae01f766ad09c',  // 上面步骤提到的key复制过来// e1dedc6bdd765d46693986ff7ff969f4
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  v: '1.4.4'
});

Vue.config.productionTip = false
Vue.use(Antd)
Vue.use(api)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
