import axios from '../axios'



/**
 * 1.1.3 添加/修改图片
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const save = data => {
    return axios({
        url: '/manager/houseImage/save',
        method: 'POST',
        data
    })
}

/**
 * 1.5.1  分页获取图片
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
 export const list = (houseId) => {
    return axios({
        url: '/manager/houseImage/list/' + houseId,
        method: 'GET'
    })
}


/**
 * 1.5.1  删除图片
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
 export const del = (id) => {
    return axios({
        url: '/manager/houseImage/del/' + id,
        method: 'DELETE'
    })
}