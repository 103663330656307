import * as login from './modules/login'
import * as menu from './modules/menu'
import * as village from './modules/village'
import * as customer from './modules/customer'
import * as contactInfo from './modules/contactInfo'
import * as house from './modules/house'
import * as diskSource from './modules/diskSource'
import * as houseImage from './modules/houseImage'
import * as diskSourceImage from './modules/diskSourceImage'
import * as sysTags from './modules/sysTags'
import * as sysFacil from './modules/sysFacil'
import * as houseTag from './modules/houseTag'
import * as houseFacil from './modules/houseFacil'
import * as role from './modules/role'
import * as sys from './modules/sys'
import * as userAccount from './modules/userAccount'

export default {
    login,
    menu,
    village,
    customer,
    contactInfo,
    house,
    diskSource,
    houseImage,
    diskSourceImage,
    sysTags,
    sysFacil,
    houseTag,
    houseFacil,
    role,
    sys,
    userAccount,
}
