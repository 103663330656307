import axios from '../axios'

/**
 * 1.4.1 查询用户创建的角色
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const findRolesByUser = () => {
    return axios({
        url: '/manager/sysRole/findRolesByUser',
        method: 'get'
    })
}

/**
 * 1.4.2 添加角色
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const addRole = data => {
    return axios({
        url: '/manager/sysRole/addRole',
        method: 'POST',
        data
    })
}

/**
 * 1.4.3 删除角色
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const delRole = id => {
    return axios({
        url: '/manager/sysRole/delRole/' + id,
        method: 'DELETE',
    })
}

/**
 * 1.4.4 修改角色
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const updateRole = data => {
    return axios({
        url: '/manager/sysRole/updateRole',
        method: 'PUT',
        data
    })
}

