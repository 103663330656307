import axios from '../axios'



/**
 * 1.1.3 添加标签库
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const add = data => {
    return axios({
        url: '/manager/sysTags/add',
        method: 'POST',
        data
    })
}

/**
 * 1.5.1  标签库
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
 export const list = () => {
    return axios({
        url: '/manager/sysTags/list',
        method: 'GET'
    })
}
